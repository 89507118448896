<template>
  <div>
    <b-input
      placeholder="localizar"
      type="number"
      v-model="filtro"
      pattern="\d*"
      autocomplete="off"
      ref="txtBuscaComanda"
      autofocus
      @keydown.enter="clickSel"
    />
    <hr />
    <div>
      <div
        v-for="cm in comandas"
        :key="`comanda_${cm.comanda.nome}_${Math.random()}`"
        class="comanda"
        @click="select(cm)"
      >
        {{ cm.comanda.nome }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesas: Array,
  },
  mounted() {
    this.$refs.txtBuscaComanda.focus();
  },
  data() {
    return {
      filtro: "",
    };
  },
  watch: {},
  computed: {
    comandas: function () {
      let res = [];
      for (let m of this.mesas.filter((x) => x.id)) {
        res.push(
          ...m.comandas.map((c) => ({
            mesa: m,
            comanda: c,
          }))
        );
      }
      res = res.filter(
        (x) =>
          (this.filtro &&
            this.filtro != "" &&
            x.comanda.nome.toLowerCase().indexOf(this.filtro) >= 0) ||
          !this.filtro
      );

      res = res.reduce((ret, vl) => {
        let has = ret.find((x) => x.comanda.nome == vl.comanda.nome);
        if (!has) {
          ret.push(vl);
        }
        return ret;
      }, []);
      console.log("filtrando", this.filtro);
      return res.sort((a, b) => {
        return parseInt(a.comanda.nome.replace("Comanda nº ", "")) >
          parseInt(b.comanda.nome.replace("Comanda nº ", ""))
          ? 1
          : -1;
      });
    },
  },
  methods: {
    clickSel() {
      if (this.comandas && this.comandas.length > 0) {
        this.select(this.comandas[0]);
      }
    },
    select(comanda) {
      // this.$emit("mesa", comanda.mesa);
      this.$emit("comanda", comanda.comanda);
    },
  },
};
</script>

<style lang="scss" scoped>
.comanda {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: #ccc;
    box-shadow: 0px 0px 10px rgba(160, 160, 160, 0.3);
    background-color: #f5f5f5;
  }
}
</style>
