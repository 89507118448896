<template>
  <div v-if="mesaObj" style="width: 100%; height: 100%">
    <div
      v-if="mesaObj && (mesaObj.id === null || mesaObj.id < 0)"
      class="mesaFechada pb-6"
    >
      <h2>{{ mesaObj.mesa }} Disponível</h2>
      <div style="display: block">
        <b-btn class="mt-3" variant="success" @click="openMesa(false)">
          <b-icon-book />
          ABRIR
        </b-btn>

        <b-btn class="mt-3 ml-2" variant="warning" @click="openMesa(true)">
          <b-icon-calendar-check />
          Reservar
        </b-btn>
      </div>
      <hr />
      <div class="pb-5">
        <mesa-new-reservas
          v-if="mesaObj"
          :inside="true"
          :showFiltros="false"
          :data="data"
          :codMesa="mesaObj.cod_mesa"
          :empresa="empresa"
          :mesas="mesas"
          @compareceu="compareceu"
          :initialReserva="initialReserva"
        />
      </div>
    </div>
    <div v-if="mesaObj.id === 0 || mesaObj.id > 0" class="configurarMesa p-2">
      <h3 class="text-center">
        <span v-if="!openReserve"> <b-icon-sliders /> Configuração </span>
        <span v-if="openReserve"> <b-icon-clock-history /> Reserva </span>
        da {{ mesaObj.mesa }}
      </h3>

      <hr />
      <div class="configMesaContent">
        <div v-if="reservaSelecionada">
          <b-card title="Reserva Selecionada" style="font-size: 12px">
            <div><b>Nome: </b>{{ reservaSelecionada.nome }}</div>
            <div><b>Telefone: </b> {{ reservaSelecionada.telefone }}</div>
            <div><b>Hora: </b> {{ reservaSelecionada.hora }}</div>
            <div>
              <b>Pessoas: </b> <i class="fa fa-user mr-1" />
              {{ reservaSelecionada.qtd_pessoas }}
              <i class="fa fa-child ml-3 mr-1" />
              {{ reservaSelecionada.qtd_criancas }}
            </div>
            <div>
              <b>Observações: </b>
              {{ reservaSelecionada.obs || "Sem observações" }}
            </div>
          </b-card>
        </div>

        <mesa-new-vincular-mesas
          @change="selMesa"
          :empresa="empresa"
          :mesas="mesas"
          :mesaObj="mesaObj"
          :initialSelect="[
            mesaObj.cod_mesa,
            ...mesaObj.juncao.map((i) => i.cod_mesa),
            ...selectedMesaJunto,
          ]"
          v-if="mesaObj && mesaObj.id == 0"
        ></mesa-new-vincular-mesas>
        <!-------- RESERVA FORM ----------->
        <div v-if="openReserve" class="p-0 p-sm-4 pb-5 mb-5">
          <mesa-new-reserva-form
            :empresa="empresa"
            :mesas="mesas"
            ref="reservaForm"
            :mesa="mesaObj"
            :inside="true"
          />
        </div>

        <div
          class="d-flex justify-content-between px-4 bg-light py-3"
          v-if="
            !openReserve &&
            rodizio.habilitado &&
            rodizio.opcoes &&
            rodizio.opcoes.length > 0
          "
        >
          <b-check switch class="mt-4" v-model="lancaRodizio" style="z-index: 0"
            >Lançar rodízio?</b-check
          >
          <div class="ml-4 text-right">
            <label><b>Selecione o tipo de rodízio</b></label
            ><br />
            <b-dropdown size="lg">
              <template #button-content>
                {{ rodizio.opcSelecionada ? rodizio.opcSelecionada.nome : "" }}
              </template>
              <b-dropdown-item
                style="font-size: 20px; margin-bottom: 20px"
                @click="rodizio.opcSelecionada = rod"
                v-for="rod in rodizio.opcoes"
                :key="`tpRod${rod.cod_produto}`"
              >
                <div class="w-100" style="min-width: 170px">
                  <span>{{ rod.nome }} </span>
                  <small>
                    <br />
                    {{ rod.preco_venda | currency }}
                  </small>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <!-------- Comandas List ----------->

        <div class="ComandaList" v-if="!openReserve">
          <div class="mb-2">
            <i class="fa fa-barcode" /> Lista de Comandas
            <hr />
          </div>
          <b-card
            v-for="comanda in mesaObj.comandas"
            :key="`comanda_${Math.random()}_${comanda.id}`"
            class="mb-3"
          >
            <div class="d-flex flex-wrap justify-content-sm-end">
              <div class="d-flex align-items-center w-100 w-sm-auto">
                <b-form-group
                  label="Nome da comanda (clique para alterar)"
                  class="w-100 pr-2"
                >
                  <div
                    class="input form-control selectComandaNome"
                    @click="selectNumComanda(comanda)"
                  >
                    {{ comanda.nome }}
                  </div>
                </b-form-group>
                <b-btn
                  class="mt-3"
                  @click="
                    $emit('trocarMesaComanda', {
                      comanda_id: comanda.id,
                      comanda: comanda.nome,
                    })
                  "
                  v-if="mesaObj.id"
                >
                  <i class="fas fa-exchange-alt"></i>
                </b-btn>
              </div>
              <div class="ml-2" style="flex: 1">
                <label><i class="fa fa-user" /> Inteira</label>
                <b-spinbutton v-model="comanda.qtd_pessoas" min="0" />
              </div>
              <div class="ml-2" style="flex: 1">
                <label><i class="fa fa-child" /> Meia</label>
                <b-spinbutton v-model="comanda.qtd_criancas" min="0" />
              </div>
              <div class="ml-2" style="flex: 1">
                <label><i class="fa fa-birthday-cake" /> Não Pagante</label>
                <b-spinbutton v-model="comanda.qtd_npaga" min="0" />
              </div>

              <div
                class="pl-5 pt-4 ml-sm-auto"
                style="flex: 1"
                v-if="rodizio.habilitado"
              >
                <b-check
                  switch
                  class="mt-1"
                  v-model="comanda.lancaRodizio"
                  style="z-index: 0"
                  >Lançar rodízio?</b-check
                >
              </div>
              <div
                v-if="mesaObj.comandas.length > 1"
                class="mt-4 ml-sm-auto"
                style="font-size: 20px"
              >
                <b-icon-trash
                  class="mr-4"
                  @click="removeComanda(comanda)"
                  style="font-size: 25px; cursor: pointer"
                />
              </div>
            </div>
          </b-card>
          <div
            class="btAddComanda"
            @click="addComanda"
            v-if="!mesaObj.dt_fechada"
          >
            <i class="fa fa-plus" /> Adicionar Comanda
          </div>
        </div>
      </div>
      <!-- <div class="ComandaFoot" v-if="false">
        <div v-if="!openReserve">
          <span
            ><b>Comandas: {{ mesaObj.qtd_comandas }}</b>
            <b-btn
              variant="success"
              class="ml-3"
              size="sm"
              @click="addComanda"
              style="vertical-align: baseline"
              ><b-icon-plus
            /></b-btn>
          </span>
          <br />
          <b>Adultos: {{ mesaObj.qtd_pessoas }}</b
          ><br />
          <small>
            <b>Crianças: {{ mesaObj.qtd_criancas }}</b
            ><br />
          </small>
        </div>
        <div class="botoesAcao text-right">
          <div :class="`bt ` + (salvando ? `salvando` : '')" @click="salvar">
            <b-icon-check-lg v-if="!salvando" />
            <b-spinner
              v-if="salvando"
              size="lg"
              large
              style="width: 40px; height: 40px"
            />
          </div>
          <div class="btCancel" @click="mesaObj.id = null">Cancelar</div>
        </div>
      </div> -->
    </div>
    <input type="hidden" ref="nn" />
    <b-modal
      id="modal-show-select-comanda"
      hide-footer
      @shown="$refs.txtSearchComandaOption.focus()"
    >
      <b-input
        placeholder="buscar comanda"
        pattern="\d*"
        type="number"
        v-model="searchComanda"
        class="mb-2"
        @keydown.enter="selectComandaFiltred"
        autocomplete="off"
        ref="txtSearchComandaOption"
      />
      <div
        v-for="n in comandaOptionsList"
        :key="`comdSEl${n}_${Math.random()}`"
        class="listaComandasSel"
        :class="
          (n.selected === true ? ' listaComandasSelIndisp ' : '') +
          (comandaSelect && comandaSelect.nome == 'Comanda nº ' + n.n
            ? ' listaComandasSelSelected '
            : '')
        "
        @click="selectComandaSel(n)"
      >
        Comanda nº {{ n.n }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import MesaNewReservaForm from "./MesaNewReservaForm.vue";
import MesaNewVincularMesas from "./MesaNewVincularMesas.vue";
import MesaNewReservas from "./MesaNewReservas.vue";
export default {
  components: { MesaNewReservaForm, MesaNewVincularMesas, MesaNewReservas },
  props: {
    mesaObj: Object,
    data: String,
    mesas: Array,
    mesaLayout: Object,
    empresa: Object,
    configs: Object,
  },
  mounted() {
    if (location.href.includes("#reserva=")) {
      this.initialReserva = location.href.split("#reserva=")[1];
      console.log("Reserva: " + this.initialReserva);
    }
    this.fillComandaOptions();
    // console.log("mesas", this.mesas);
    // console.log("empresa", this.empresa);

    this.conf = JSON.parse(
      JSON.stringify(Object.assign({}, { ...this.empresa }))
    );

    if (this.mesaObj) {
      this.vincularMesaAutomatico(this.mesaObj.cod_mesa);
    }
    // console.log(
    //   "CONFIG",
    //   this.conf,
    //   moment().format("d"),
    //   this.conf.configs.rodizioConfig,
    //   this.conf.configs.rodizioConfig.find(
    //     (x) => x.day.toString() == moment().format("d").toString()
    //   ),
    //   this.conf.configs.rodizioConfig.find(
    //     (x) => x.day.toString() == moment().format("d").toString()
    //   ).lancaRodizio
    // );

    if (
      this.conf &&
      this.conf.configs &&
      this.conf.configs.rodizioConfig &&
      this.conf.configs.rodizioConfig.find(
        (x) => x.day.toString() == moment().format("d").toString()
      ) &&
      this.conf.configs.rodizioConfig.find(
        (x) => x.day.toString() == moment().format("d").toString()
      ).lancaRodizio &&
      this.mesaObj &&
      this.mesaObj.comandas
    ) {
      let rodConfig = this.conf.configs.rodizioConfig.find(
        (x) => x.day.toString() == moment().format("d").toString()
      );
      console.log("cardARIO ------->", this.$store.state.cardapio);
      this.rodizio.habilitado = true;
      if (rodConfig) {
        let fndOpts = this.$store.state.cardapio.filter((x) =>
          x.produtos.find((t) =>
            rodConfig.rodizioCompleto.includes(t.cod_produto)
          )
        );
        if (fndOpts) {
          this.rodizio.config = rodConfig;
          this.rodizio.opcoes = fndOpts.reduce((ret, vl) => {
            let has = vl.produtos.filter((x) =>
              rodConfig.rodizioCompleto.includes(x.cod_produto)
            );
            if (has) {
              ret.push(
                ...has.map((it) => ({
                  cod_produto: it.cod_produto,
                  nome: it.nome,
                  preco_venda: it.preco_venda,
                }))
              );
            }
            return ret;
          }, []);
        }
        this.rodizio.opcSelecionada =
          this.rodizio.opcoes && this.rodizio.opcoes.length > 0
            ? this.rodizio.opcoes[0]
            : null;
        console.log("ROD", this.rodizio);
      }
      this.lancaRodizio = true;
      for (let it of this.mesaObj.comandas) {
        it.lancaRodizio = true;
        console.log("IT", it);
      }
    }
    this.removerComandas = [];
    this.searchComanda = "";
    if (this.$refs && this.$refs.txtSearchComandaOption) {
      this.$refs.txtSearchComandaOption.focus();
    }
  },
  data() {
    return {
      searchComanda: "",
      salvando: false,
      mesaList: [],
      openReserve: false,
      reservaSelecionada: null,
      conf: null,
      MesaObj: null,
      noChg: false,
      comandaSelect: null,
      comandaOptions: [],
      initialReserva: null,
      selectedMesaJunto: [],
      removerComandas: [],
      lancaRodizio: false,
      rodizio: {
        habilitado: false,
        opcoes: [],
        opcSelecionada: null,
      },
    };
  },
  watch: {
    lancaRodizio: function () {
      for (let it of this.mesaObj.comandas) {
        it.lancaRodizio = this.lancaRodizio;
      }
    },
    empresa: {
      deep: true,
      handler() {},
    },
    mesaObj: {
      deep: true,
      handler() {
        if (this.noChg) return;
        if (this.mesaObj.id && this.mesaObj.id !== 0) {
          this.salvando = false;
        }
        if (this.mesaObj.id === 0 && !this.mesaObj.qtd_comandas) {
          if (!this.mesaObj.qtd_comandas) {
            this.mesaObj.qtd_comandas = 1;
          }
          if (!this.mesaObj.qtd_pessas) {
            this.mesaObj.qtd_pessoas = 1;
          }
          if (!this.mesaObj.qtd_criancas) {
            this.mesaObj.qtd_criancas = 1;
          }
          if (!this.mesaObj.qtd_criancas) {
            this.mesaObj.qtd_criancas = 1;
          }
          if (!this.mesaObj.qtd_criancas) {
            this.mesaObj.qtd_npaga = 1;
          }
          if (!this.mesaObj.comandas || this.mesaObj.comandas.length <= 0) {
            this.mesaObj.comandas = [];
            this.addComanda();
          }
        }

        if (this.mesaObj && this.mesaObj.qtd_comandas) {
          let qtd_pessoas = this.mesaObj.comandas.reduce((ret, vl) => {
            ret += vl.qtd_pessoas;
            return ret;
          }, 0);

          let qtd_criancas = this.mesaObj.comandas.reduce((ret, vl) => {
            ret += vl.qtd_criancas;
            return ret;
          }, 0);

          if (this.mesaObj.qtd_criancas != qtd_criancas) {
            this.mesaObj.qtd_criancas = qtd_criancas;
          }
          if (this.mesaObj.qtd_pessoas != qtd_pessoas) {
            this.mesaObj.qtd_pessoas = qtd_pessoas;
          }
          if (this.mesaObj.qtd_comandas != this.mesaObj.comandas.length) {
            this.mesaObj.qtd_comandas = this.mesaObj.comandas.length;
          }
        }
        if (this.mesaObj && this.mesaObj.reserva && this.mesaObj.reserva.id) {
          this.reservaSelecionada = this.mesaObj.reserva;
          console.log("RESERVA SELEC", this.reservaSelecionada);
        }
        this.fillComandaOptions();
      },
    },
    mesaList: {
      deep: true,
      handler() {
        this.mesaObj.juncao = this.mesaList
          .filter((x) => x.selected === true)
          .map((x) => ({
            cod_mesa: x.cod_mesa,
          }));
      },
    },
  },
  computed: {
    mesasSelecionadas: function () {
      return this.mesaList.filter((x) => x.selected === true);
    },
    comandaOptionsList: function () {
      return this.comandaOptions.filter((x) =>
        this.searchComanda && this.searchComanda != ""
          ? x.n == this.searchComanda
          : true
      );
    },
  },
  methods: {
    selectComandaFiltred() {
      if (this.comandaOptionsList && this.comandaOptionsList.length > 0) {
        this.selectComandaSel(this.comandaOptionsList[0]);
      }
    },
    fillComandaOptions() {
      // console.log("mesas", this.mesas);
      this.comandaOptions = [];
      for (let i = 1; i <= 300; i++) {
        this.comandaOptions.push({
          n: i,
          selected:
            this.mesas &&
            this.mesas.some(
              (x) =>
                x.comandas &&
                x.comandas.some((y) => y.nome == "Comanda nº " + i)
            ),
        });
      }
    },
    selectComandaSel(n) {
      // if (this.comandaSelect.nome) {
      let h = this.comandaOptions.find(
        (x) => "Comanda nº " + x.n == this.comandaSelect.nome
      );
      console.log("hold", h);
      if (h) {
        h.selected = false;
      }
      // }
      if (!n.selected) {
        n.selected = true;
        this.comandaSelect.nome = "Comanda nº " + n.n;
        this.$bvModal.hide("modal-show-select-comanda");
      }
    },
    closeComandaSelect() {
      this.$bvModal.hide("modal-show-select-comanda");
      this.$refs.nn.focus();
      this.searchComanda = "";
      this.$refs.txtSearchComandaOption.focus();
    },
    selectNumComanda(comanda) {
      this.comandaSelect = comanda;
      this.$bvModal.show("modal-show-select-comanda");
      this.searchComanda = "";
      this.$refs.txtSearchComandaOption.focus();
    },
    async compareceu(reserva) {
      this.reservaSelecionada = null;
      let res = await this.$swal({
        title: "O responsável pela reserva compareceu?",
        text: `${reserva.nome} - ${moment(reserva.data).format(
          "DD/MM/YYYY"
        )} ás ${reserva.hora} para ${reserva.mesa}`,
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Compareceu!",
        cancelButtonText: "Cancelar",
      }).then((x) => x.isConfirmed);
      if (res) {
        this.reservaSelecionada = reserva;
        this.openMesa(false);
      }
    },
    selMesa(mesa) {
      // console.log("MESA", mesa);
      this.mesaList = mesa;
    },
    openMesa(reserve) {
      this.mesaObj.id = 0;
      this.openReserve = reserve;
      this.$emit("changeMenu", "Config");
    },
    async vincularMesaAutomatico(cod_mesa) {
      if (
        this.mesaLayout &&
        this.mesaLayout.layout &&
        this.mesaLayout.layout.length > 0
      ) {
        let hasLigacao = this.mesaLayout.layout.find((r) =>
          r.items.some((it) => it.cod_mesa == cod_mesa && it.uniao)
        );
        console.log("hasLigacao", hasLigacao);
      }
    },
    selectMesa(mesa) {
      mesa.selected = !mesa.selected;
      this.$bvModal.hide("modal-show-mesas-openeds");
    },
    vincularMesa() {
      this.$bvModal.show("modal-show-mesas-openeds");
    },
    async salvar() {
      if (this.openReserve) {
        let result = await this.$refs.reservaForm.salvar();
        if (result) {
          this.$emit("changeMenu", "Pedido");
          this.$emit("close");
        }

        return;
      }
      if (!this.salvando) {
        this.salvando = true;

        //return;
        if (!this.mesaObj.id) {
          this.mesaObj.dt_aberta =
            moment(this.$props.data).format("YYYY-MM-DD ") +
            moment().format("HH:mm:ss");
        }
        this.loading = true;

        console.log("open mesa or reserve", this.openReserve, {
          mesa: this.mesaObj,
          reserva: this.reserva,
        });

        this.mesaObj.reservaSelecionada = this.reservaSelecionada;
        console.log("SENDING", this.mesaObj);
        this.$socket.emit("mesas:configMesa", {
          ...this.mesaObj,
          removerComandas: this.removerComandas,
          rodizio: this.rodizio,
        });
        this.$emit("changeMenu", "Pedido");
      }
    },
    addComanda() {
      this.noChg = true;
      this.$nextTick();

      let numDisp = 1;
      while (
        (this.mesas &&
          this.mesas.some(
            (x) =>
              x.comandas &&
              x.comandas.some((y) => y.nome == "Comanda nº " + numDisp)
          )) ||
        this.comandaOptions.some(
          (y) =>
            "Comanda nº " + y.n == "Comanda nº " + numDisp &&
            y.selected === true
        )
      ) {
        numDisp++;
      }

      this.mesaObj.comandas.push({
        nome: "Comanda nº " + numDisp,
        qtd_pessoas: 1,
        qtd_criancas: 0,
        qtd_npaga: 0,
        taxa_servico: 0,
        lancaRodizio:
          this.conf &&
          this.conf.configs &&
          this.conf.configs.rodizioConfig &&
          this.conf.configs.rodizioConfig.find(
            (x) => x.day.toString() == moment().format("d").toString()
          ) &&
          this.conf.configs.rodizioConfig.find(
            (x) => x.day.toString() == moment().format("d").toString()
          ).lancaRodizio,
      });
      let fnd = this.comandaOptions.find(
        (x) => "Comanda nº " + x.n == "Comanda nº " + numDisp
      );
      if (fnd) {
        fnd.selected = true;
      }

      this.noChg = false;
    },
    removeComanda(comanda) {
      if (
        comanda.id &&
        this.mesaObj &&
        this.mesaObj.pedidos &&
        this.mesaObj.pedidos.some(
          (x) => x.comanda_id == comanda.id && !x.excluido
        )
      ) {
        this.$swal(
          "Não permitido!",
          "A comanda que está tentando remover já possui pedidos! Transfira os pedidos para outra comanda antes de excluir",
          "error"
        );
        return;
      }
      this.removerComandas.push(Object.assign({}, { ...comanda }));
      this.mesaObj.comandas = this.mesaObj.comandas.filter((x) => x != comanda);
    },
  },
};
</script>

<style lang="scss" scoped>
.listaComandasSel {
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
}
.listaComandasSelSelected {
  border-color: green;
  background-color: lightgreen !important;
}
.listaComandasSelIndisp {
  opacity: 0.5;
  color: #111;
  background-color: #aeaeae;
}
.mesaFechada {
  text-align: center;
  padding-top: 20px;
  position: absolute;
  top: 0px;
  bottom: 60px;
  right: 0px;
  left: 0px;
  overflow: hidden;
  overflow-y: auto;
  button {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 20px;
  }
}
.ComandaList {
  // position: fixed;
  // top: 190px;
  // left: 10px;
  // right: 10px;
  // bottom: 170px;
  padding: 10px;
  // padding-bottom: 150px;

  min-height: 600px;
  display: flex;
  flex-flow: column;
  padding-bottom: 200px;

  .btAddComanda {
    width: 100%;
    min-height: 60px;
    font-size: 20px;
    border-radius: 15px;
    background-color: #f2f2f2;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #888;
    cursor: pointer;
    transition: all 0.1s ease;
    &:hover {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    }
  }

  // background-color: #f8f8f8;
}
.ComandaFoot {
  position: fixed;
  @media only screen and(min-width:575px) {
    position: absolute;
    bottom: 0px;
    height: 60px;
  }
  border-top: 1px solid #ccc;
  left: 0px;
  bottom: 60px;
  right: 0px;
  height: 100px;
  padding: 10px;
  font-size: 18px;
  z-index: 1;
  background-color: #fff;
  .botoesAcao {
    .salvando {
      opacity: 0.95;
      padding-top: 15px !important;
      background-color: #333 !important;
      font-size: 10px !important;
    }
    .bt {
      position: absolute;
      right: 10px;
      top: -30px;
      padding-top: 5px;
      width: 70px;
      height: 70px;
      background-color: rgb(77, 134, 28);
      color: white;
      text-align: center;
      font-size: 40px;
      border-radius: 50%;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      }
    }
    .btCancel {
      position: absolute;
      right: 10px;
      top: 50px;
      padding-top: 7px;
      width: 90px;
      height: 35px;
      background-color: rgb(74, 74, 74);
      color: white;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.configMesaContent {
  position: fixed;
  @media only screen and(min-width:575px) {
    position: absolute;
  }
  top: 75px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  overflow: hidden;
  overflow-y: auto;
}

.configurarMesa {
}

.selectComandaNome {
  cursor: pointer;
}
</style>
