<template>
  <div>
    <div
      class="mesa"
      :class="comandaSel ? 'mesaSelecionada' : ''"
      @click="openComandas"
    >
      <i class="fa fa-barcode mr-1" />
      {{ !comandaSel ? "Todas as Comandas" : comandaSel.nome }}
    </div>
    <b-modal
      id="modal-comandas"
      hide-footer
      title="Selecione a comanda que deseja visualizar"
    >
      <div class="caixaTopoMesaInsider">
        <div class="listamesas">
          <div class="cmd" @click="selecionarComanda(null)">
            <i class="fa fa-barcode mr-1" />
            Todas as Comandas
          </div>
          <hr />
          <div
            class="cmd comandaInfos"
            v-for="comanda in mesaObj.comandas"
            :key="`comanda_${comanda.id}`"
            @click="selecionarComanda(comanda)"
          >
            <div class="ml-2">
              <h4><i class="fa fa-barcode mr-1" /> {{ comanda.nome }}</h4>
            </div>
            <div>
              <div class="ml-2 text-right">
                <span class="ml-4">
                  <label class="ml-1"><i class="fa fa-user" /> Adultos</label>
                  {{ comanda.qtd_pessoas }}
                </span>
                <span>
                  <label class="ml-1"><i class="fa fa-child" /> Crianças</label>
                  {{ comanda.qtd_criancas }}
                </span>
              </div>
            </div>
          </div>
           <div
            class="cmd p-4 pt-4" @click="gerenciarComandas">
            <h5 class="m-3"><i class="fa fa-cogs mr-1"/> GERENCIAR COMANDAS </h5>
          </div>
        </div>
      </div>
   
    </b-modal>
  </div>
</template>

<script>
// import MesaNewConfig from './MesaNewConfig.vue'
export default {
  // components: {MesaNewConfig},
  props: {
    mesaObj: Object,
    comandaSel: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    openComandas() {
      this.$bvModal.show("modal-comandas");
    },
    selecionarComanda(comanda) {
      this.$bvModal.hide("modal-comandas");
      this.$emit("selecionar", comanda);
    },
    gerenciarComandas() {
      this.$emit('gerenciarComandas');
    },
    closeComandas() {
      this.$bvModal.hide("modal-comandas");
      
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mesas.scss";
.comandaInfos {
  display: flex;
  justify-content: space-between !important;
  height: 80px !important;
  div {
    padding: 10px;
  }
}
</style>
