<template>
  <div class="mb-5">
    <b-card class="" body-class="pb-4">
      <b-card-title>
        <h4 class="mb-0">
          {{
            `Resumo ${mesaVisu ? getMesaNome(mesaVisu) : ""} ${
              comanda ? `- ${comanda.nome}` : ""
            }`
          }}
          <span v-if="mesaObj && mesaObj.reserva  && mesaObj.reserva.id  && mesaObj.reserva.id>0" style="font-size:10px">
            <b-icon-calendar /> Reserva de {{ mesaObj.reserva.nome }}
          </span>
          <small class="float-right"
            ><small>Conta nº {{ mesaObj.id || "" }}</small></small
          >
        </h4>
      </b-card-title>

      <div class="d-flex justify-content-between">
        <div>Subtotal</div>
        <div>{{ subtotal | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Taxa Serviço ({{ mesaObj.taxa_servico }}%)</div>
        <div>{{ taxa | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold">
        <div>Total</div>
        <div>{{ total | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold mt-3 menor">
        <div>Pago</div>
        <div>{{ pago | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: red"
      >
        <div>Restante</div>
        <div>{{ restante | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: #ccc"
        v-if="mesaVisu"
      >
        <div>Pago Rateado</div>
        <div>{{ pagoRateado | currencyMask }}</div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="position: absolute; bottom: -25px; right: -0px"
        v-if="!mesaObj.dt_fechada"
      >
        <b-btn
          size="lg"
          variant="info"
          class="mr-1"
          @click="fechar"
          v-if="showFechar && !comanda"
        >
          <i class="fas fa-clipboard-check"></i> Fechar
        </b-btn>

        <b-btn
          v-if="showButtons"
          size="lg"
          variant="danger"
          class="mr-1"
          @click="removeTaxa"
          ><b-icon-percent
        /></b-btn>
        <b-btn
          v-if="showButtons"
          size="lg"
          variant="success"
          class="mr-1"
          @click="addPgto"
          ><b-icon-currency-dollar
        /></b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    comanda: Object,
    showFechar: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // console.log("COMANDASEL", this.comanda);
  },
  data() {
    return {};
  },
  watch: {
    "$.props": {
      deep: true,
      handler() {
        console.log("COMAND", this.comanda);
      },
    },
  },
  computed: {
    subtotal() {
      return this.mesaObj.pedidos
        .filter((x) => !x.excluido || x.excluido == 0)
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comanda ? x.comanda_id == this.comanda.id : true)
        )
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
    },
    taxa() {
      return this.comanda? this.comanda.vl_taxa_servico:this.mesaObj.vl_taxa_servico
    },
    total() {
      return this.subtotal + this.taxa;
    },
    pago() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter(
              (x) =>
                (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
                (this.comanda ? x.comanda_id == this.comanda.id : true)
            )
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    pagoRateado() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter((x) => x.ratear == 1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    restante() {
      return this.total - this.pago;
    },
  },
  methods: {
    addPgto() {
      this.$emit("addPgto");
    },
    removeTaxa() {
      console.log("removeTaxa 00");
      this.$emit("removeTaxa");
      this.$emit("removerTaxa", 123);
      this.$forceUpdate();
      console.log("removeTaxa 00 emitted");
    },
    fechar() {
      console.log(this.total, this.pago);
      if (this.total - 0.01 > this.pago) {
        this.$swal("Atenção", "Valor pago inferior ao da conta!", "error");
        return;
      } else {
        console.log("fechar01");
        this.$emit("fecharMesa");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menor {
  font-size: 10px !important;
}
</style>
