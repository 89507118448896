<template>
  <div>
    <form @submit.prevent="addPgto">
      <div class="row mt-3">
        <!-- <div class="col-12 font-weight-bold">Pagamento em</div> -->
        <div class="col-12 font-weight-bold">
          Adicionar / Editar Pagamento 
          <span v-if="restante">
            Falta {{ restante }}
          </span>
          <span
            v-if="
              mesaVisu || (pagamento && pagamento.id && pagamento.ratear === 0)
            "
          >
            {{ getMesaNome(mesaVisu || mesa.cod_mesa)
            }}<small v-if="comanda"
              ><br />
              {{ comanda ? comanda.nome : "" }}
            </small>
          </span>
        </div>
        <div class="col-12 mt-3">
          <b-form-group label="Forma de Pagamento">
            <b-radio-group
              title="Forma de Pagamento"
              buttons
              button-variant="success"
              v-model="forma"
            >
              <b-radio value="DINHEIRO" button>Dinheiro</b-radio>
              <b-radio value="CARTAO" button>Cartão</b-radio>
              <b-radio value="PIX" button>PIX</b-radio>
              <b-radio value="VALE" button v-if="authAdm" button-variant="info"
                >Vale</b-radio
              >
            </b-radio-group>
            <b-btn variant="warning" @click="solicitarLoginAdm" v-if="!authAdm"
              ><b-icon-lock /> VALE</b-btn
            >
          </b-form-group>
        </div>
        <div class="col-12" v-if="forma != 'PIX'">
          <div class="d-flex">
            <b-form-group label="Valor Pago">
              <b-input
                v-model="valor"
                pattern="\d*"
                ref="txtValor"
                v-money="moneyMask"
              />
            </b-form-group>
            <div class="pl-2">
              <b-form-group label="Troco p/">
                <b-input
                  v-model="troco_p"
                  pattern="\d*"
                  ref="txtValorTroco"
                  v-money="moneyMask"
                />
              </b-form-group>
            </div>
            <div class="pl-2">
              <label>Troco</label>
              <div class="form-control w-100">{{ troco | currencyMask }}</div>
            </div>
          </div>
        </div>
        <div class="col-12" v-if="forma == 'PIX'">
          <pix-payment
            :mesa="true"
            @concluido="setPixInfo"
            :dadosIniciais="dados_pagamento"
          />
          <hr />
        </div>
        <div class="col-12">
          <b-form-group label="Observação">
            <b-input v-model="obs" />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2 text-right">
          <hr />
          <b-button v-if="pagamento && pagamento.id>0" variant="danger" class="float-left" style="" @click="delPgto(pagamento)">
            <b-icon-trash /> Excluir  
          </b-button>
          <b-button
            :variant="pagamento ? 'warning' : 'success'"
            type="button"
            :disabled="salvando"
            @click="addPgto"
          >
            <span v-if="!pagamento"> <b-icon-plus /> Adicionar </span>
            <span v-else> <i class="fa fa-edit" /> Editar </span>
          </b-button>
          <b-button variant="secondary" @click="cancel()" class="ml-1"
            ><i class="fa fa-ban" /> Cancelar</b-button
          >
        </div>
      </div>
    </form>

    <login-admin
      v-if="loginAdm"
      @result="loginResult"
      :idExterno="mesa.id"
      :tipo="'mesa'"
      :obs="'MESA: Forma de pagamento (VALE)'"
    />
  </div>
</template>

<script>
import LoginAdmin from "../../components/LoginAdmin.vue";
import PixPayment from "../../components/Caixas/PixPayment.vue";

export default {
  components: { LoginAdmin, PixPayment },
  props: {
    mesa: Object,
    comanda: Object,
    pagamento: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    getMesa: Function,
    comandaSel: Object,
    restante: Number,
  },
  mounted() {
    if (this.pagamento) {
      console.log("PAGAMENTO", this.pagamento);
      this.forma = this.pagamento.forma;
      this.valor = this.formatFloatToReal(this.pagamento.valor);
      this.obs = this.pagamento.obs;
      this.dados_pagamento =
        this.pagamento.dados_pagamento && this.pagamento.dados_pagamento.PIX
          ? this.pagamento.dados_pagamento.PIX
          : null;
    }
  },
  data() {
    return {
      troco_p: "R$ 0,00",
      forma: "",
      valor: "R$ 0,00",
      troco: 0,
      dados_pagamento: null,
      obs: "",
      salvando: false,
      loginAdm: false,
      ratear: 0,
      authAdm:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0,
    };
  },
  watch: {
    forma: function () {
      //      this.$refs.txtValor.focus();
    },
    troco_p: function () {
      this.calcTroco();
    },
    valor: function () {
      this.calcTroco();
    },
  },
  computed: {},
  methods: {
    calcTroco() {
      try {
        this.troco =
          this.formatValorReal(this.troco_p||0) - this.formatValorReal(this.valor||0) ||
          0;
        if (this.troco == "NaN") {
          this.troco = 0;
        }
      } catch {
        this.troco = 0;
      }
      // console.log("troco: ", this.troco)
    },
    setPixInfo(dados) {
      this.dados_pagamento = { PIX: dados };
      if (dados) {
        this.valor = dados.valor;
      } else {
        this.valor = 0;
      }
      console.log(dados);
    },
    solicitarLoginAdm() {
      if (!this.authAdm || this.authAdm == false) {
        this.loginAdm = true;
        return false;
      } else {
        return true;
      }
    },
    loginResult(result) {
      this.loginAdm = false;
      this.authAdm = !result ? false : result ? true : false;
    },
    cancel() {
      this.$emit("cancelar");
    },
    delPgto(pgto) {
      console.log("delPgto", pgto);
      this.$swal({
        title: "Tem Certeza?",
        text: "Deseja excluir este pagamento?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then(async (x) => {
        if (x.isConfirmed) {
          let cancelPix = true;
          if (pgto && pgto.dados_pagamento && pgto.dados_pagamento.PIX) {
            cancelPix = await this.$swal({
              title: "PAGAMENTO PIX!",
              text: "Deseja DEVOLVER o PIX para o cliente? Após devolvido não será possível reverter. Se não devolver poderá utilizar o pagamento novamente pelo identificador.",
              showCancelButton: true,
              cancelButtonText: "Não devolver.",
              confirmButtonText: "Sim. Devolver!",
            }).then((c) => c.isConfirmed);
          }
          console.log("DEVOLVER PIX", cancelPix);
          // console.log(222222);
          pgto.cod_usuario = this.user.cod_usuario;
          this.$socket.emit("mesas:deletePagamento", {
            pgto,
            mesa: this.mesa,
            cancelPix,
          });
          setTimeout(() => {
            this.$emit("carregar");
            this.cancel();
          }, 200);
        }
      });
    },
    addPgto(e) {
      if (e) e.preventDefault();
      if (this.forma == "") {
        this.$swal("Erro", "Selecione uma forma de pagamento!", "error");
        return;
      }
      console.log(this.forma, this.dados_pagamento);
      if (
        this.forma == "PIX" &&
        (!this.dados_pagamento ||
          !this.dados_pagamento.PIX ||
          this.dados_pagamento.PIX.status != "PAGO")
      ) {
        this.$swal("Erro", "PIX não PAGO / CONCLUIDO!", "error");
        return;
      }
      if (this.valor == "" || this.formatValorReal(this.valor) <= 0) {
        this.$swal("Erro", "Informe o valor para pagamento!", "error");
        return;
      }
      this.salvando = true;
      let obj = {
        mesa: this.mesaVisu ? this.getMesa(this.mesaVisu) : this.mesa,
        comanda: this.comandaSel,
        pgto: {
          forma: this.forma,
          valor: this.formatValorReal(this.valor),
          obs: this.obs,
          id: this.pagamento ? this.pagamento.id : null,
          dados_pagamento: this.dados_pagamento,
          cod_usuario: this.user.cod_usuario,
          ratear: this.pagamento
            ? this.pagamento.ratear
            : this.mesaVisu
            ? 0
            : 1,
        },
      };
      console.log("enviando obj", obj);
      this.$socket.emit("mesas:addPagamento", obj);
      this.$emit("carregar");

      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped></style>
